<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
          <el-breadcrumb-item>账单明细</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-link type="primary" @click="$router.back()">&lt;返回</el-link>
      </el-col>
    </el-row>
    <el-divider class="div_der"></el-divider>
    <bill-table filter></bill-table>
  </div>
</template>

<script>
/**
 * Bills
 * 账单列表
 */
import BillTable from "@/views/income/components/BillTable"
export default {
  name: "Bills",
  components:{
    BillTable
  },
  data(){
    return {
      //   累计收益
      cashOutData: {},
    }
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";
.div_der{
  margin-top: 15px;
  margin-bottom: 40px;
}
.header-title{
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  letter-spacing: 0px;
}
.label{
  display: flex;
  padding: 0;
  margin: 0 0 24px 0;
    >li{
      list-style: none;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      margin-right: 44px;
    }
}
</style>
